import React from "react";
import useTranslations from "../../../components/useTranslations";
import Footer from "../../../components/Footer/Footer";
import CompanyLeftNav from "../../../components/TwoColumnLeftNav/CompanyLeftNav";
import {LocaleContext} from "../../../components/layout";

const Privacy = () => {
  // useTranslations is aware of the global context (and therefore also "locale")
  // so it'll automatically give back the right translations
  const { privacy } = useTranslations();
  const { locale } = React.useContext(LocaleContext)

  const JaContent = () => {
    return(
        <div className="privacy flex column">
          <div className="privacy-header medium">
            株式会社佐藤計量器製作所（以下「当社」といいます。）は、当社の提供するサービスにおける、お客様の個人情報の取り扱いについて、以下のとおりプライバシーポリシー（以下「本ポリシー」といいます。）を定めます。
          </div>
          <div className="privacy-content">
            <div className="flex column privacy-box">
              <div className="medium privacy-box-title medium bold">
                1. 基本的な考え
              </div>
              <div className="medium privacy-box-content medium">
                当社では、当社ホームページ（以下「当サイト」という）において提供するサービス（当サイトによる情報提供、各種ご意見・ご質問の受付・回答等）の円滑な運営に必要な範囲で、当サイトを利用されるお客様の情報を収集・利用させて頂きます。
                <br />
                収集した情報は利用目的の範囲内で適切に取り扱います。
                <ul>
                  <li>
                    <p>(1)法令の遵守</p>
                    <p>お客様の個人情報に関して、適用される関係法令およびその他の規範を遵守して管理、取り扱いをおこないます。</p>
                  </li>
                  <li>
                    <p>(2)個人情報の取り扱い</p>
                    <p>お客様の個人情報について、不正アクセス・漏えい・改ざん・滅失などを防止するために、改善等の取り組みに努め、適切な予防処置を講じます。</p>
                  </li>
                  <li>
                    <p>(3)セキュリティ</p>
                    <p>当サイトは、お客様の個人情報を保護するためにSSLに対応しています。</p>
                    <p>セキュリティ機能に対応したブラウザーを使用することで、お客様が入力される個人情報が自動的に暗号化されて送受信されるため、第三者による閲覧や改ざんを防ぎます。</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex column privacy-box">
              <div className="medium privacy-box-title medium bold">
                2. 収集する個人情報および収集・利用方法について
              </div>
              <div className="medium privacy-box-content medium">
                個人情報の収集に関しては、あらかじめ利用目的を定め、お知らせするとともに、その目的の達成に必要な範囲内で適正な方法により収集、利用いたします。
                <br />
                また、関係法令に定める場合を除き、あらかじめ本人の同意を得ることなく、収集した個人情報を利用目的以外には使用いたしません。
              </div>
            </div>
            <div className="flex column privacy-box">
              <div className="medium privacy-box-title medium bold">
                3. 個人情報の利用目的について
              </div>
              <div className="medium privacy-box-content medium">
                お客様の個人情報に関する当社の利用目的は以下のとおりです。
                <ul>
                  <li>
                    <p>(1)製品（製品に係る部品、付属品を含む）の発送・配送、カタログ等の資料送付</p>
                  </li>
                  <li>
                    <p>(2)製品に関する各種お問い合わせ、ご依頼の対応</p>
                  </li>
                  <li>
                    <p>(3)製品・サービスの情報、企業情報、展示会などのイベントに関する情報提供</p>
                  </li>
                  <li>
                    <p>(4)製品・サービスに関するアンケートおよび調査、その結果の集計や分析</p>
                  </li>
                  <li>
                    <p>(5)製品・サービスの企画・開発のため</p>
                  </li>
                  <li>
                    <p>(6)本ポリシーに関する規約などの変更を通知</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex column privacy-box">
              <div className="medium privacy-box-title medium bold">
                4. 個人情報の第三者提供への提供について
              </div>
              <div className="medium privacy-box-content medium">
                当社は、次の場合を除いて、あらかじめ本人の同意を得ることなく、第三者に個人情報を提供いたしません。
                <ul>
                  <li>
                    <p>(1)個人情報保護法以外の他の法令に基づき、個人情報を第三者提供する場合</p>
                  </li>
                  <li>
                    <p>(2)人の生命、身体または財産の保護のために個人情報の第三者提供が必要である場合であって、本人の同意を得ることが困難であるとき。</p>
                  </li>
                  <li>
                    <p>(3)公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき。</p>
                  </li>
                  <li>
                    <p>(4)国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障をおよぼすおそれがあるとき。</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex column privacy-box">
              <div className="medium privacy-box-title medium bold">
                5. 業務の外部委託について
              </div>
              <div className="medium privacy-box-content medium">
                当社は、製品の配送業務や当社製品に係る調査などの利用目的達成のために、必要な範囲において個人情報の取り扱いを第三者に提供することがあります。
                <br />
                その場合、当該委託先による不正な取り扱い、漏洩等を防ぐために、適切な事業者を選択するとともに、契約を終結するなどの適切な措置を講じます。
              </div>
            </div>
            <div className="flex column privacy-box">
              <div className="medium privacy-box-title medium bold">
                6. 個人情報の開示・変更・削除・利用停止について
              </div>
              <div className="medium privacy-box-content medium">
                当社は、お客様から個人情報の開示・変更・削除・利用停止を求められたときは、第三者への個人情報の漏えいおよび改ざんを防止するため、お客様ご本人からのご請求であることを確認の上で、すみやかに対応させて頂きます。
              </div>
            </div>
            <div className="flex column privacy-box">
              <div className="medium privacy-box-title medium bold">
                7. お問い合わせ窓口
              </div>
              <div className="medium privacy-box-content medium">
                当社への個人情報の取り扱いに関するお問い合わせは、下記の窓口までご連絡ください。<br /><br />
                <p>株式会社佐藤計量器製作所　SKSATOオンラインショップ係</p>
                <p>電話（フリーダイヤル）：0120-389-513</p><br />
                <p>受付時間：平日：午前10時30分〜午後3時まで</p>
                <p>（休業日：土日、祝日、夏季および年末年始）</p>
              </div>
            </div>
            <div className="flex column privacy-box">
              <div className="medium privacy-box-title medium bold">
                8. プライバシーポリシーの変更について
              </div>
              <div className="medium privacy-box-content medium">
                当社は、必要に応じて本ポリシーを変更します。<br />
                変更内容につきましては、当サイト上でお知らせいたします。<br /><br />
                <p>制定日：2007年7月6日</p>
                <p>改訂日：2021年5月1日</p>
                <p>株式会社佐藤計量器製作所</p>
              </div>
            </div>
          </div>
        </div>
    )
  }

  const EnContent = () => {
    return(
        <div className="privacy flex column">
          <div className="privacy-header medium">
            This Privacy Policy addresses how Sato Keiryoki Mfg. Co., Ltd. (hereinafter referred to as “we”) handles your personal information in respect to our service.
          </div>
          <div className="privacy-content">
            <div className="flex column privacy-box">
              <div className="medium privacy-box-title medium bold">
                1. Basic Principles
              </div>
              <div className="medium privacy-box-content medium">
                We use and collect personal information of those who use our website to accomplish our services, such as providing our information and response to inquiries.<br/>
                We handle your information we collect for our purposes appropriately in the ways described below:
                <ul>
                  <li>
                    <p>(a) Legal Compliance</p>
                    <p>We comply with legal, regulatory, and other ethical requirements related to personal information.</p>
                  </li>
                  <li>
                    <p>(b) Protection</p>
                    <p>We take measures to protect your personal information from unauthorized access, leakage, defacing, or loss, and improve such measures as needed.</p>
                  </li>
                  <li>
                    <p>(c) Security</p>
                    <p>For security of your personal information, we use the Secure Sockets Layer (SSL) protocol, which encrypts any information you enter before it is sent to us to prevent from skimming or defacing by third parties, as long as you use a browser with security function.</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex column privacy-box">
              <div className="medium privacy-box-title medium bold">
                2. Means of Collection and Use of Personal Information
              </div>
              <div className="medium privacy-box-content medium">
                We disclose our purposes of use of personal information before collecting it.<br/>
                We use and collect your personal information via proper means within the scope necessary to achieve our purposes.<br/>
                We shall not use your information for other purposes than our purposes prescribed without your consent, except in cases based on laws and regulations related to personal information.
              </div>
            </div>
            <div className="flex column privacy-box">
              <div className="medium privacy-box-title medium bold">
                3. Purposes of Use of Personal Information
              </div>
              <div className="medium privacy-box-content medium">
                Our purposes of use of your personal information are as follows:
                <ul>
                  <li>
                    <p>(a) delivery of our products, including assembly and attachments, and our information materials; </p>
                  </li>
                  <li>
                    <p>(b) response to inquiries and requests related to our products; </p>
                  </li>
                  <li>
                    <p>(c) providing information of our products and services, our corporate news, and our events such as exhibitions; </p>
                  </li>
                  <li>
                    <p>(d) market research and its analysis of our products and services; </p>
                  </li>
                  <li>
                    <p>(e) development of our products and services; </p>
                  </li>
                  <li>
                    <p>(f) notification of a change in this Privacy Policy.</p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex column privacy-box">
              <div className="medium privacy-box-title medium bold">
                4. Sharing Personal Information with Third Parties
              </div>
              <div className="medium privacy-box-content medium">
                We shall not share your personal information with third parties without your consent except in the following instances:
                <ul>
                  <li>
                    <p>(a) based on laws or regulations other than Japan’s Personal Information Protection Act; </p>
                  </li>
                  <li>
                    <p>(b) in which there is a need to protect human life, body, or property, and when it is difficult to obtain your consent; </p>
                  </li>
                  <li>
                    <p>(c) in which there is a special need to enhance public hygiene or promote fostering healthy children, and when it is difficult to obtain your consent; </p>
                  </li>
                  <li>
                    <p>(d) in which there is a need to cooperate in regard to government organizations or a person entrusted by them performing affairs prescribed by laws and regulations, and when there is a possibility that obtaining your consent may interfere with the performance of the said affairs. </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex column privacy-box">
              <div className="medium privacy-box-title medium bold">
                5. Outsourcing of Handling Personal Information
              </div>
              <div className="medium privacy-box-content medium">
                We may share your personal information with third parties within the scope necessary to accomplish our purposes, such as delivery of our products and research related to our products. <br />
                In such case, we take appropriate measures to choose proper parties and sign a contract with them in order to prevent from unauthorized handling or leakage of the information by them.
              </div>
            </div>
            <div className="flex column privacy-box">
              <div className="medium privacy-box-title medium bold">
                6. Disclosure, Update, Removal, and Suspension of Use of Personal Information
              </div>
              <div className="medium privacy-box-content medium">
                If you wish to access, update, remove, or stop the use of your information, we will allow you to do so with least delay, after the confirmation that the information certainly belongs to you so as to prevent from leakage or defacing by third parties.
              </div>
            </div>
            <div className="flex column privacy-box">
              <div className="medium privacy-box-title medium bold">
                7. Contact
              </div>
              <div className="medium privacy-box-content medium">
                If you have questions about your personal information or this Privacy Policy, email us at query@sksato.co.jp. <br/>
                Please note that this email box is not monitored on weekends, Japan’s national holidays, summer holidays, and the new year holidays.
              </div>
            </div>
            <div className="flex column privacy-box">
              <div className="medium privacy-box-title medium bold">
                8. Changes in Privacy Policy
              </div>
              <div className="medium privacy-box-content medium">
                We may make changes to this Privacy Policy as needed. We will do so by posting modifications to our website.<br/><br/>
                <p>Enacted on July 6, 2007</p>
                <p>Last revised on May 1, 2021</p>
              </div>
            </div>
          </div>
        </div>
    )
  }

  return (
    <>
      <article className="company-info two-column-left-nav">
        <CompanyLeftNav />
        <section className="main-content">
          <h2 className={"large bold"}>{privacy}</h2>
          <div className="content-container">
            {(locale === 'ja')?
                <JaContent/>:
                <EnContent/>
            }
          </div>
        </section>
      </article>
      <Footer />
    </>
  );
};

export default Privacy;
